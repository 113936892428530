export default function ({ store, app: { router } }) {
    router.beforeEach((to, from, next) => {
        const cartVerified = store.getters['order/cartVerified'];
        const cartItems = store.getters['order/items'];
        const isCheckout = to.path === '/checkout';
        const isFrontpage = to.path === '/';

        // redirect user to index if they try to go to checkout without cart is verified
        if ((isCheckout && !cartVerified) || (isCheckout && !cartItems.length)) {
            if (!window.$nuxt) {
                window.onNuxtReady(() => {
                    return window.$nuxt.$router.push('/');
                });
            } else {
                return router.push('/');
            }
        }

        /* if (from && isFrontpage && !store.getters.desktopCheckoutStarted) {
            setTimeout(() => {
                store.commit('order/resetOrder');
                if (cartItems.length) {
                    store.commit('order/clearCart');
                }
            }, 500);
        } */

        store.commit('setTransition', 'left');

        if (
            from.path.includes('user-settings') ||
            from.name === 'checkout' && to.name !== 'posts-concept' && to.name !== 'order-uuid' ||
            to.name === 'index' ||
            from.name === 'posts-concept' && to.name === 'checkout'
        ) {
            store.commit('setTransition', 'right');
        }

        store.commit('routes', { to: to.name, from: from.name });
        next();
    });
}
