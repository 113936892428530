export default function ({ store, app }) {
    // Watch for theme changes
    store.watch(
        state => state.supplier.theme,
        newTheme => {
            console.log('Theme changed:', newTheme);
            
            if (newTheme) {
                console.log('Applying new theme');
                // Apply theme colors to CSS variables
                const root = document.documentElement;
                
                // Primary colors
                root.style.setProperty('--primary-color', newTheme.primaryColor || '#000000');
                root.style.setProperty('--secondary-color', newTheme.secondaryColor || '#ffffff');
                
                // Text colors
                root.style.setProperty('--text-color', newTheme.textColor || '#000000');
                root.style.setProperty('--text-color-light', newTheme.textColorLight || '#666666');
                
                // Background colors
                root.style.setProperty('--background-color', newTheme.backgroundColor || '#ffffff');
                root.style.setProperty('--background-color-alt', newTheme.backgroundColorAlt || '#f5f5f5');
                
                // Button colors
                root.style.setProperty('--button-color', newTheme.buttonColor || '#000000');
                root.style.setProperty('--button-text-color', newTheme.buttonTextColor || '#ffffff');

                // Add a class to body to indicate themed state
                document.body.classList.add('supplier-themed');
                
                // Update all favicons
                if (newTheme.favicon || newTheme.favicons) {
                    // Update main favicon
                    const mainFavicon = document.querySelector('link[rel="icon"][type="image/x-icon"]');
                    if (mainFavicon && newTheme.favicon) {
                        mainFavicon.href = newTheme.favicon;
                    }

                    // Update PNG favicons
                    if (newTheme.favicons) {
                        // Update 16x16
                        const favicon16 = document.querySelector('link[rel="icon"][sizes="16x16"]');
                        if (favicon16 && newTheme.favicons[16]) {
                            favicon16.href = newTheme.favicons[16];
                        }

                        // Update 32x32
                        const favicon32 = document.querySelector('link[rel="icon"][sizes="32x32"]');
                        if (favicon32 && newTheme.favicons[32]) {
                            favicon32.href = newTheme.favicons[32];
                        }

                        // Update 96x96
                        const favicon96 = document.querySelector('link[rel="icon"][sizes="96x96"]');
                        if (favicon96 && newTheme.favicons[96]) {
                            favicon96.href = newTheme.favicons[96];
                        }

                        // Update Apple touch icons
                        const appleTouchIcons = document.querySelectorAll('link[rel="apple-touch-icon"]');
                        appleTouchIcons.forEach(icon => {
                            // Use the largest available favicon for apple touch icons
                            icon.href = newTheme.favicons[96] || newTheme.favicons[32] || newTheme.favicons[16];
                        });
                    }

                    // If icons don't exist in head, create them
                    const head = document.head;
                    if (!document.querySelector('link[rel="icon"][type="image/x-icon"]') && newTheme.favicon) {
                        const link = document.createElement('link');
                        link.rel = 'icon';
                        link.type = 'image/x-icon';
                        link.href = newTheme.favicon;
                        head.appendChild(link);
                    }

                    Object.entries(newTheme.favicons || {}).forEach(([size, path]) => {
                        if (!document.querySelector(`link[rel="icon"][sizes="${size}x${size}"]`)) {
                            const link = document.createElement('link');
                            link.rel = 'icon';
                            link.type = 'image/png';
                            link.sizes = `${size}x${size}`;
                            link.href = path;
                            head.appendChild(link);
                        }
                    });
                }
                
                // Update meta theme color
                const metaThemeColor = document.querySelector('meta[name="theme-color"]');
                if (metaThemeColor) {
                    metaThemeColor.content = newTheme.primaryColor || '#000000';
                }

                console.log('Theme applied successfully');
            } else {
                console.log('Resetting to default theme');
                // Reset to default theme
                const root = document.documentElement;
                root.style.removeProperty('--primary-color');
                root.style.removeProperty('--secondary-color');
                root.style.removeProperty('--text-color');
                root.style.removeProperty('--text-color-light');
                root.style.removeProperty('--background-color');
                root.style.removeProperty('--background-color-alt');
                root.style.removeProperty('--button-color');
                root.style.removeProperty('--button-text-color');

                // Remove themed class
                document.body.classList.remove('supplier-themed');

                // Reset favicons to default
                const defaultFavicon = '/favicon/favicon.ico';
                const defaultFavicon16 = '/favicon/favicon-16x16.png';
                const defaultFavicon32 = '/favicon/favicon-32x32.png';
                const defaultFavicon96 = '/favicon/favicon-96x96.png';

                document.querySelectorAll('link[rel="icon"]').forEach(icon => {
                    const size = icon.getAttribute('sizes');
                    if (size === '16x16') icon.href = defaultFavicon16;
                    else if (size === '32x32') icon.href = defaultFavicon32;
                    else if (size === '96x96') icon.href = defaultFavicon96;
                    else if (icon.type === 'image/x-icon') icon.href = defaultFavicon;
                });

                document.querySelectorAll('link[rel="apple-touch-icon"]').forEach(icon => {
                    icon.href = defaultFavicon96;
                });
            }
        },
        { immediate: true }
    );

    // Apply theme immediately if it exists in store
    const currentTheme = store.state.supplier.theme;
    if (currentTheme) {
        console.log('Applying initial theme from store');
        const root = document.documentElement;
        root.style.setProperty('--primary-color', currentTheme.primaryColor || '#000000');
        root.style.setProperty('--secondary-color', currentTheme.secondaryColor || '#ffffff');
        root.style.setProperty('--text-color', currentTheme.textColor || '#000000');
        root.style.setProperty('--text-color-light', currentTheme.textColorLight || '#666666');
        root.style.setProperty('--background-color', currentTheme.backgroundColor || '#ffffff');
        root.style.setProperty('--background-color-alt', currentTheme.backgroundColorAlt || '#f5f5f5');
        root.style.setProperty('--button-color', currentTheme.buttonColor || '#000000');
        root.style.setProperty('--button-text-color', currentTheme.buttonTextColor || '#ffffff');
        document.body.classList.add('supplier-themed');
    }
} 