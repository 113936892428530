import Vue from 'vue'
import VueMapkit from 'vue-mapkit'

// const tokenId = 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjZGTEdHWFFOR04ifQ.eyJpc3MiOiI3NFo1Wk0yV1FWIiwiaWF0IjoxNjU1Mjk5MjUzLCJleHAiOjE2NTc4OTExMjUsIm9yaWdpbiI6Imh0dHBzOi8vZmlkZGxlLmpzaGVsbC5uZXQifQ.82WtUyFXRfjbTitxY7mp9txUR6fA4K2M9ktp14NR4eNjzd7HiSyqO5X72V1AqqQvoj_oPCk4fm0ODsufKa_Ipw';
const tokenId = 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjZGTEdHWFFOR04ifQ.eyJpc3MiOiI3NFo1Wk0yV1FWIiwiaWF0IjoxNjU1MzAxMDcyLCJleHAiOjE2ODY3ODcyMDAsIm9yaWdpbiI6Imh0dHA6Ly9sb2NhbGhvc3Q6MzgwMCJ9.rCOrFG7iBEDrlvIHRa1aBikoV5quIwVFE634Z6HAwU-5yKfT8G8SNAoo0q6TmbQkf9gKB6OdxyE7K-3PL01DDA';

Vue.use(VueMapkit, {
    authorizationCallback(done) {
        // provide mapkit jwt here
        done(tokenId);
    },
    // you can use options from mapkit documentation as well
    language: 'da',
})