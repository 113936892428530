import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { version } from '../package.json';

const bugsnagClient = Bugsnag.start({
    apiKey: process.env.NUXT_ENV_BUGSNAG_KEY,
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.NUXT_ENV_APP_ENV,
    enabledReleaseStages: ['production', 'staging'],
    appVersion: version,
});

Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);

BugsnagPerformance.start({
    apiKey: process.env.NUXT_ENV_BUGSNAG_KEY,
    appVersion: version,
    releaseStage: process.env.NUXT_ENV_APP_ENV,
});

export default (ctx, inject) => {
    inject('bugsnag', bugsnagClient);
};
