export default function ({ app, store, redirect, route }) {
    // Check if maintenance mode is enabled (you can set this via an environment variable)
    const isMaintenanceMode = process.env.MAINTENANCE_MODE === 'true';
    
    if (isMaintenanceMode) {
        // Handle SSR route
        if (route.path !== '/maintenance') {
            redirect('/maintenance');
            return;
        }

        // Handle client-side navigation
        app.router.beforeEach((to, from, next) => {
            if (to.path === '/maintenance') {
                next();
            } else {
                next('/maintenance');
            }
        });

        // Handle initial client load
        if (process.client && window.location.pathname !== '/maintenance') {
            window.location.href = '/maintenance';
        }
    }
} 