import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import lazySizes from 'lazysizes';
lazySizes.cfg.expand = 1;
lazySizes.cfg.lazyClass = 'lazy';
lazySizes.cfg.preloadAfterLoad = true;

document.addEventListener('lazybeforeunveil', function (e) {
    const bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});
