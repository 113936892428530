import posthog from 'posthog-js';
import { nextTick } from 'vue';

export default function ({ app, app: { router } }, inject) {
    // Init PostHog
    posthog.init('phc_bFlvYz5EUQr7Cu3AWQBtxxa82RvMbtceouQotX5LKCU', {
        api_host: 'https://goose.tribehappiness.com',
        person_profiles: 'always',
        capture_pageview: false,
        loaded: () => {
            // Only identify if user is already logged in
            if (app.$auth && app.$auth.loggedIn && app.$auth.user && app.$auth.user.email) {
                posthog.identify(app.$auth.user.email, {
                    email: app.$auth.user.email,
                    name: app.$auth.user.name,
                    phone: app.$auth.user.phone_number,
                    has_corporate_account: !!app.$auth.user.corporate_account,
                    $created: app.$auth.user.created_at,
                });
            }
        },
    });

    // Create a method to track orders
    const trackOrder = order => {
        try {
            const cartItems = JSON.parse(order.cart_json).cart;
            const orderProperties = {
                order_id: order.uuid,
                order_number: order.order_number,
                restaurant_name: order.restaurant_location?.restaurant?.name,
                restaurant_location: order.restaurant_location?.name,
                delivery_type: order.delivery_option?.type,
                total_amount: order.total,
                items_count: cartItems.length,
                items: cartItems.map(item => ({
                    name: item.name,
                    quantity: item.quantity,
                    price: item.price,
                })),
                state: order.state,
                created_at: order.created_at,
                expected_delivery_at: order.expected_delivery_at,
                expected_pickup_at: order.expected_pickup_at,
                preorder_time: order.preorder_time,
                has_corporate_account: !!order.corporate_account_id,
                has_delivery_instructions: !!order.comment_delivery_driver,
                has_order_comment: !!order.comment,
            };

            // Identify the user by email
            if (order.email) {
                posthog.identify(order.email, {
                    email: order.email,
                    name: order.name,
                    has_corporate_account: !!order.corporate_account_id,
                    last_order_date: order.created_at,
                });
            }

            posthog.capture('order_created', orderProperties);
        } catch (error) {
            console.error('Failed to track order in PostHog:', error);
        }
    };

    // Create a method to identify users when they log in
    const identifyUser = user => {
        try {
            if (user && user.email) {
                posthog.identify(user.email, {
                    email: user.email,
                    name: user.name,
                    phone: user.phone_number,
                    has_corporate_account: !!user.corporate_account,
                    $created: user.created_at,
                });

                // Also capture a login event
                trackLogin(user, 'login');
            }
        } catch (error) {
            console.error('Failed to identify user in PostHog:', error);
        }
    };

    // Create a method to track login events
    const trackLogin = (user, method = 'login') => {
        try {
            if (user && user.email) {
                posthog.capture('user_login', {
                    login_method: method,
                    email: user.email,
                    name: user.name,
                    has_corporate_account: !!user.corporate_account,
                    timestamp: new Date().toISOString(),
                });
            }
        } catch (error) {
            console.error('Failed to track login event in PostHog:', error);
        }
    };

    // Create a method to track checkout events with basket contents
    const trackCheckout = (cart, user = null) => {
        try {
            if (!cart || !cart.length) {
                console.warn('Cannot track empty checkout');
                return;
            }

            const checkoutProperties = {
                items_count: cart.length,
                items: cart.map(item => ({
                    name: item.name,
                    quantity: item.quantity,
                    price: item.price,
                    id: item.id,
                })),
                total_amount: cart.reduce((total, item) => total + item.price * item.quantity, 0),
                timestamp: new Date().toISOString(),
            };

            // Add user info if available
            if (user) {
                checkoutProperties.user_email = user.email;
                checkoutProperties.user_id = user.id;
                checkoutProperties.has_corporate_account = !!user.corporate_account;
            }
            posthog.capture('checkout_started', checkoutProperties);
        } catch (error) {
            console.error('Failed to track checkout in PostHog:', error);
        }
    };

    // Create a method to track company account creation
    const trackCompanyAccountCreation = (companyData, user = null) => {
        try {
            const companyProperties = {
                company_name: companyData.name,
                has_vat_number: !!companyData.vat_no,
                timestamp: new Date().toISOString(),
            };

            // Add user info if available
            if (user) {
                companyProperties.user_email = user.email;
                companyProperties.user_id = user.id;
                companyProperties.user_name = user.name;
            }

            posthog.capture('company_account_created', companyProperties);
        } catch (error) {
            console.error('Failed to track company account creation in PostHog:', error);
        }
    };

    // Inject PostHog and the tracking methods into the application
    inject('posthog', posthog);
    inject('trackOrder', trackOrder);
    inject('identifyUser', identifyUser);
    inject('trackLogin', trackLogin);
    inject('trackCheckout', trackCheckout);
    inject('trackCompanyAccountCreation', trackCompanyAccountCreation);

    // Make sure that pageviews are captured with each route change
    router.afterEach(to => {
        nextTick(() => {
            /* Note: this might also be a good place to call posthog.register(...) in order to update your properties
      on each page view
      */
            posthog.capture('$pageview', {
                $current_url: to.fullPath,
            });

            // Track checkout page visits
            if (to.path === '/checkout') {
                // If the user navigates to the checkout page, we'll track that event
                // The actual cart data will be provided by the checkout page component
            }

            // Setup page leave tracking
            const handlePageLeave = () => {
                posthog.capture('$pageleave', {
                    $current_url: to.fullPath,
                    leave_time: new Date().toISOString(),
                });
            };

            // Clean up previous listener if exists
            window.removeEventListener('beforeunload', handlePageLeave);
            // Add new listener
            window.addEventListener('beforeunload', handlePageLeave);
        });
    });

    // Listen for auth state changes to identify the user
    if (app.$auth) {
        app.$auth.$storage.watchState('loggedIn', loggedIn => {
            if (loggedIn) {
                const user = app.$auth.user;
                identifyUser(user);
            }
        });
    }
}
